/*For xs - sm size*/
@media only screen and (max-width: 959px) {
  #mobile-app-title {
    text-align: center;
  }
  #mobile-app-header {
    margin-bottom: 0;
  }
  .mg-up30{
    margin-top: 0px;
  }
  .navbar-fixed .img-logo {
    width: 150px;
  }
  .navbar.navbar-fixed button {
    font-size: 9px;
  }
  #link-logo {
    margin-top: 7px;
  }
  .navbar-fixed #link-logo {
    margin-top: 4px;
  }
  .hidden-xs {
    display: none !important;
  }
  .nav-home {
    height: auto;
  }
  #hoyo-about-title {
    text-align: center;
  }
  .about-content {
    padding: 40px 0px 40px 0px;
    text-align: left;
  }
  .testi-slot {
    padding: 0 0 20px 8px;
  }
}

/*For xs - md size*/
@media only screen and (max-width: 1279px) {

}

/*For xs - lg size*/
@media only screen and (max-width: 1919px) {

}

/*For xl size*/
@media only screen and (min-width: 1920px) {

}


/*For sm - xl size*/
@media only screen and (min-width: 600px) {
}
/*For md - xl size*/
@media only screen and (min-width:960px) {
  #dashboard-buttons-area button {
    /* color: #6b7c93; */
    color: rgb(107, 124, 147, 0.88);
    width: 153px;
    text-transform: none;
    padding-top: 17px;
    padding-bottom: 16px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(100, 100, 100, 0.5);
    font-weight: normal;
    background-color: white;
  }
  #dashboard-buttons-area button:hover{
    background-color: #e6e6e6;
  }

  #dashboard-buttons-area button {
    text-decoration: none;
    margin-bottom: 20px;
    text-decoration: none;
  }

  #dashboard-buttons-area button.active {
    color: white;
    background-color: #3d7dc8;
    text-decoration: none;
  }

}

/* lg & xl */
@media (min-width:1280px) {
  .container {
    width: 1140px !important;
  }

  #dashboard-buttons-area {
    margin-right: 60px;
  }
}

/* md only */
@media (min-width:960px) and (max-width: 1279px) {
  .container {
    width: 900px !important;
  }

  .db-box {
    padding: 14px 15px 16px;
  }
  #dashboard-buttons-area {
    margin-right: 50px;
  }
  .sub-navbar{
    width: 90%;
    margin: 0 auto;
  }
  .sub-navbar-change{
    width: 90%;
    margin: 0 auto;
  }
  .footer-content{
    width: 80%;
    text-align: justify;
  }

  .card {
    width: 100%;
  }

  #home-banner-container {
    padding-left: 0;
  }
  #navbar-menu-container {
    padding-left: 25px;
  }
  .testi-slot {
    padding: 10px;
  }
}

/* sm only */
@media (min-width:600px) and (max-width: 959px) {
  #dashboard-buttons-area button {
    /* color: #6b7c93; */
    color: rgb(107, 124, 147, 0.88);
    width: 153px;
    text-transform: none;
    padding-top: 17px;
    padding-bottom: 16px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(100, 100, 100, 0.5);
    font-weight: normal;
    background-color: white;
  }
  #dashboard-buttons-area button:hover{
    background-color: #e6e6e6;
  }

  #dashboard-buttons-area button {
    text-decoration: none;
    margin-bottom: 20px;
    text-decoration: none;
  }

  #dashboard-buttons-area button.active {
    color: white;
    background-color: #3d7dc8;
    text-decoration: none;
  }
  .container{
    width: 580px !important;
  }
  .center-sm {
    text-align: center;
  }
  #dashboard-buttons-area {
    margin: 0;
  }
  #dashboard-buttons-area button {
    width: 144px;
  }
  #dashboard-buttons-area a {
    text-decoration: none;
    margin-bottom: 5px;
  }
  #home-banner-container {
    padding-left: 0;
  }
}



/* xs only */
@media (max-width:599px) {
  .container {
    width: 92%;
  }
  #form-invest {
    text-align: left;
  }
  button.button-navbar{
    color: #1A100C;
    margin-left: 15px;
  }
  .sub-navbar {
    height: 80px;
  }
  .menu-other {
    text-align: left;
  }
  #home-banner-container {
    padding-left: 0;
    width: 81%;
    padding-top: 320px;
    text-align: center;
  }
  #home-banner-container button {
    width: 100%;
  }

  .testi-photo > div {
    width: 95%;
    height: 230px;
    margin: 0 auto;
  }
  .testi-content {
    padding: 10px;
  }
  .photo-story {
    width: 50%;
  }
  .mobile-grid-container {
    width: auto !important;
    margin: 0 !important;
  }
  .mobile-grid-container > div {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .photo-why {
    min-width: 140px;
  }
  .img-logo {
    margin-top: 25px;
  }
  #banner-title {
    font-size: 28px;
    text-align: left;
    line-height: inherit;
  }
  #banner-subtitle {
    display: none;
  }
  #bg-banner {
    background-image: url('../img/bg-banner-mobile.jpg');
    background-position: right top;
    background-size: cover;
    height: 680px;
  }
  #home-button-group {
    margin-top: 16px;
  }
  #menu-list-grow-user {
    width: 175px;
    background-color: #F5F4E9;
    border-radius: 10px;
  }
  .slick-slide .home-card {
    width: 290px;
  }
  #button-wa {
    right: 20px;
  }
  #bt-shopee {
    margin-right: 0;
  }
  #bt-toped {
    margin-top: 15px;
    margin-left: 0;
  }
  #container-testi {
    margin-top: 95px;
  }
}

/* special case */
@media (min-width:342px) and (max-width: 409px) {

}

@media (max-width:320px){

}
