@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
body {
  display: block;
  padding: 0;
  margin: 0 auto;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  vertical-align: baseline;
  color: #314A37;
}

.color-theme {
  /* color: #F5F4E9 !important; */
  color: #314A37 !important;
}
.color-light {
  color: #F5F4E9 !important;
}

button[type="button"], button[type="submit"], label[role="button"] {
  font-family: 'Poppins', sans-serif;
  text-transform: none;
  /* box-shadow: none; */
  font-weight: 400;
  border-radius: 10px;
}

.menu-other a > li {
  font-family: 'Poppins', sans-serif;
  color: #1A100C;
  font-weight: 500;
  font-size: 16px;
}
.important-links a, .db-box a {
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  color: #F5F4E9;
  font-weight: 500;
  font-size: 16px;
  /* margin-bottom: 8px; */
  display: block;
}

.important-links .link-section {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;
}

.my-form-control .my-form-label, .my-form-control .my-select-placeholder {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: #aaa;
}

.my-form-control .my-form-label.label-focused {
  color: '#314A37';
}

.my-form-control .my-form-input, .my-form-control .my-form-input-outlined, .my-form-control .my-form-select {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: #6b7c93;
  text-align: left;
}

.my-form-control .my-form-select {
  padding: 17px 8px 20px 17px;
  border-radius: 10px;
}

.my-form-control .input-underline:before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.my-form-control .input-underline:hover:not(.input-disabled):not(.input-focused):not(.input-error):before {
  border-bottom: 2px solid rgba(0, 0, 0, 0.5);
}

#form-prohouse .my-form-input-outlined, #form-prohouse .my-form-select {
  background-color: white;
}
#form-prohouse .my-form-input-outlined > input {
  padding: 17.5px 14px;
}
.my-form-control .my-form-input-outlined {
  border-radius: 10px;
}
.my-form-input-outlined.input-focused>fieldset {
  border-color: #314A37 !important;
}

.my-form-control .input-underline:after {
  border-bottom-color: #314A37;
}

.my-form-control .my-form-label.label-shrink {
  font-weight: 700;
  color: #6b7c93;
}

.my-form-control input {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: #314A37;
}

.react-select-ic>div {
  padding: 4.5px;
  cursor: pointer;
}
#form-prohouse span.my-radio {
  padding: 1px 13px;
}
span.my-radio {
  /* padding-right: 5px; */
}
span.my-radio.radio-checked {
  color: #B25416;
}
span.my-radio svg {
  font-size: 20px;
}

.navbar {
  border: none;
  background-color: transparent;
}

.invisible {
  max-height: 0;
  transition: max-height 0.5s ease-out;
  overflow: hidden;
  padding-bottom: 0px;
}

.navbar a {
  text-decoration: none;
}

.navbar.navbar-fixed {
  width: 100%;
  border: none;
  background-image: linear-gradient(to top, #2475d1, #1b4c91 100%, #1f5fae, #13285a);
  position: absolute;
  z-index: 100;
  top: 0;
  height: 64px;
  box-shadow: 0 -6px 40px 4px #111;
}
.navbar-fixed .sub-navbar {
  height: 64px;
}


/* this is to make sure dom structure not collapsing when fixed occur */
.sub-navbar-dummy {
  height: 100px;
}

.sub-navbar {
  height: 100px;
  display: -webkit-flex;
  /* Safari */
  -webkit-align-items: center;
  /* Safari 7.0+ */
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}

div.soft-paper {
  border-radius: 3px;
  box-shadow: 0 6px 8px 0 rgba(164, 164, 164, 0.21);
}

.img-logo {
  width: 120px;
}

.img-error {
  border: 1px solid #f44336;
}

.navbar button {
  text-transform: none;
  color: #1d5d91;
  font-family: 'Work Sans', sans-serif;
  font-size: 16px;
}
a .MuiButton-label-134 {
  text-transform: none;
}

button.button-navbar {
  margin-right: 15px;
  background: transparent;
  outline: none;
  cursor: pointer;
  border: none;
  padding: 10px;
}
button.dull-button {
  box-shadow: none;
  /* background-color: none; */
  padding: 0;
}
button.dull-button:hover {
  box-shadow: none;
  /* background-color: transparent; */
}

.navbar a.active button.button-navbar, .navbar button.contain-active, .navbar a.active li {
  font-weight: 600;
  /* min-width: 120px */
}
.navbar a.active button.button-navbar::before, .navbar button.contain-active::before {
  content: "·";
  font-size: 25px;
  position: absolute;
  left: 1px;
  top: 4px;
}
.navbar a.active li::before {
  content: "\B7";
  font-size: 25px;
  position: absolute;
  left: 6px;
  top: 12px;
}

button.button-navbar-bottom {
  margin-right: 25px;
  margin-left: 25px;
  background: transparent;
  outline: none;
  cursor: pointer;
  border: none;
  padding: 10px;
  color: white !important;
}

.navbar-home {
  color: white;
}

.bottom-navbar {
  box-shadow: 0 2px 4px 0 rgba(100, 100, 100, 0.5);
  background-color: white;
}

.navbar-home button {
  color: #1d5d91;
}

.navbar-fixed {
  color: white;
}

.navbar-fixed button {
  margin-top: 2px;
  color: white;
}

.button-navbar .material-icons {
  font-size: 16px;
}

.button-menu {
  background: transparent;
  outline: none;
  cursor: pointer;
  border: none;
  font-family: 'Work Sans', sans-serif;
  font-size: 16px;
  color: #13285a;
}

.button-menu-bottom {
  background: transparent;
  border: 2px solid white;
  border-radius: 3px;
  outline: none;
  color: white;
  border: none;
  font-family: 'Work Sans', sans-serif;
  font-size: 16px;
}

.navbar-fixed .button-menu {
  border: 2px solid white;
  color: white;
  margin-top: 3px;
  padding-top: 3px;
  border-radius: 5px;
  width: 50px;
}

.menu-position {
  text-align: right;
  margin-right: 6%;
}

.button-navbar:hover {
  border-radius: 5px;
  background-color: #fff2;
}

.navbar>.sub-navbar {
  /* Safari 6.1+ */
  flex: 1 1;
}

.button-orange {
  color: #F5F4E9 !important;
  background-color: #B25416 !important;
  border: 1px solid #B25416 !important;
  padding: 10px;
  border-radius: 10px;
}

.button-orange:hover {
  color: #F5F4E9 !important;
  background-color: #AC6A3D !important;
}

.button-orange-trans {
  color: #B25416 !important;
  background-color: none !important;
  border: 1px solid #B25416 !important;
  padding: 10px;
  border-radius: 10px;
}

.button-orange-trans:hover {
  color: #B25416 !important;
  background-color: #FFFFFF11 !important;
}

.button-black-trans {
  color: #131C15 !important;
  background-color: none !important;
  border: 1px solid #D6D6D6 !important;
  padding: 10px;
  border-radius: 5px;
}
.button-black-trans:disabled {
  color: rgba(19, 28, 21, 0.25) !important;
}

.button-white-trans {
  color: white !important;
  background-color: none !important;
  border: 1px solid white !important;
  padding: 10px;
  border-radius: 10px;
}

.button-white-trans:hover {
  color: white !important;
  background-color: #FFFFFF11 !important;
}

#mobile-drawer a {
  text-decoration: none;
}
#mobile-menu .list {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: #6b7c93;
}
#mobile-menu .list-root {
  padding-top: 8px;
  padding-bottom: 8px;
}
#mobile-menu .list-item-root {
  padding: 0 8px;
}
.navbar .button-hamburger {
  line-height: 6px;
  font-size: 10px;
  padding: 8px 6px 8px 24px;
}
.button-hamburger .material-icons {
  font-size: 32px;
}
.sub-menu-mobile {
  padding: 15px;
  color: #fff;
  background-image: linear-gradient(120deg, #3F3131 0%, #9c592c 100%);
}


.fontsize-medbig {
  font-size: 18px !important;
}

.fontsize-big {
  font-size: 20px !important;
}


.fade10000-enter {
  opacity: 0.01;
}
.fade10000-enter.fade10000-enter-active {
  opacity: 1;
  transition: opacity 10000ms ease-out;
}
.fade10000-exit {
  opacity: 1;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
.fade10000-exit.fade10000-exit-active {
  opacity: 0.01;
  transition: opacity 10000ms ease-out;
}

.fade500-enter {
  opacity: 0.01;
}
.fade500-enter.fade500-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-out;
}
.fade500-exit {
  opacity: 1;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
.fade500-exit.fade500-exit-active {
  opacity: 0.01;
  transition: opacity 500ms ease-out;
}

#preloader-wrapper {
  opacity: 0;
  transition: opacity 500ms ease-out;
}
#preloader-wrapper > #pre-circle {
  width: 280px;
  transition: width 500ms ease-out;
}
#preloader-wrapper > #pre-logo {
  width: 100px;
  transition: width 500ms ease-out;
}

div.transition-group {
  position: relative;
}

section.route-section, .wrapper-anim {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.navbar button {
  text-transform: none;
  color: #F5F4E9;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
}

/*FOR ANIMATION*/
.scale-in-ver-top {
  animation: scale-in-ver-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

div.rodal {
  z-index: 1001;
}
div.rodal-dialog {
  padding: 0;
  border-radius: 10px;
  max-width: 93%;
}

div.rodal-mask {
  background: rgba(0, 0, 0, 0.5);
}
button.rodal-btn-close {
  position: absolute;
  right: -20px;
  top: -20px;
  background-color: white;
  min-width: 0px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  z-index: 1002;
}

button.rodal-btn-close:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.navbar a {
  text-decoration: none;
}

/* END OF AUTH STYLES */

@keyframes scale-in-ver-top {
  0% {
    transform: scaleY(0);
    transform-origin: 100% 0%;
    opacity: 1;
  }

  100% {
    transform: scaleY(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
}

#bg-banner {
  background-image: url(/static/media/bg-banner-1.ea9dc263.jpg);
  background-color: #0D0600;
  background-position: left;
  background-size: cover;
  background-repeat: no-repeat;
  height: 700px;
}

.container {
  margin: 0 auto;
}

.center {
  transform: scale(1.05);
}

#banner-title {
  font-size: 36px;
  font-weight: 700;
  line-height: 54px;
  color: #F5F4E9;
  width: 470px;
  max-width: 100%;
}
#banner-subtitle {
  width: 320px;
  max-width: 100%;
}

.centered {
  text-align: center !important;
}

.slick-slide .home-card {
  transform: scale(0.9);
  transition: all .3s ease;
  opacity: 0.7;
  width: 540px;
  max-width: 100%;
}
.slick-slide.slick-active.slick-current .home-card {
  transform: scale(1);
  transition: all .3s ease;
  opacity: 1;
}

.buy-img {
  border-radius: 5px;
  width: 100px;
}
.buy-variant {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 6px;
}
.buy-field {
  /* margin-top: 15px; */
}
.buy-field-adr {

}
button.buy-button-counter {
  min-width: 30px;
  padding: 0;
  border-radius: 5px;
}

.slick-slide > div > div {
  outline:none;
}

.summary-line {
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
}
button.btn-see-variant {
  margin-left: 0;
  width: 0;
  min-width: 0;
  opacity: 0;
  transition: 0.3s;
}
.card-option:hover button.btn-see-variant {
  margin-left: 40px;
  width: 100px;
  min-width: 0;
  opacity: 1;
}
.image-gallery-image {
  border-radius: 10px;
}
.image-gallery-thumbnail-image {
  border-radius: 8px;
}
button.image-gallery-nav {
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 46%;
  z-index: 10;
  min-width: 0;
  width: 36px;
  height: 36px;
}
.list-size {
  display: inline-block;
  background: #FFFFFF;
  border: 1px solid #D6D6D6;
  box-sizing: border-box;
  border-radius: 5px;
  width: 32px;
  text-align: center;
  margin-right: 10px;
}
a.btn-socmed {
  color: white;
  border-radius: 50%;
  background-color: #fff1;
  width: 45px;
  height: 45px;
  padding: 10px;
  min-width: 0;
  margin-right: 10px;
}
#home-banner-container {
  padding-left: 118px;
  padding-top: 200px;
}
#navbar-menu-container {
  padding-left: 50px;
}
#hoyo-about-title {
  font-size: 18px;
  margin-bottom: 18px;
}
#hoyo-about-desc {
  font-size: 16px;
}
.testi-slot {
  padding: 15px;
}
.testi-photo > div {
  width: 166px;
  height: 230px;
}
.testi-content {
  padding: 20px;
}
.about-content {
  padding: 40px;
}
.photo-story {
  width: 100%;
  display: block;
  border-radius: 10;
  margin: 0 auto;
}
.photo-why {
  min-width: 150px;
}
a {
  cursor: pointer;
}
.links-footer {
  margin-bottom: 8px;
}
.option-label {
  color: white;
  position: absolute;
  bottom: 24px;
  width: 100%;
  font-weight: 600;
  font-size: 28px;
}
#home-button-group {
  margin-top: 40px;
}
#button-wa {
  right: 35px;
}
.button-ecomm {
  background-color: white !important;
  text-align: center;
  height: 150px;
  border-radius: 10px;
  border: 1px solid #DFDDCC !important;
}
.button-ecomm > img {
  margin-top: 36px;
}
#bt-shopee {
  margin-right: 15px;
}
#bt-toped {
  margin-left: 15px;
}
#container-testi {
  margin-top: 120px;
}

/*For xs - sm size*/
@media only screen and (max-width: 959px) {
  #mobile-app-title {
    text-align: center;
  }
  #mobile-app-header {
    margin-bottom: 0;
  }
  .mg-up30{
    margin-top: 0px;
  }
  .navbar-fixed .img-logo {
    width: 150px;
  }
  .navbar.navbar-fixed button {
    font-size: 9px;
  }
  #link-logo {
    margin-top: 7px;
  }
  .navbar-fixed #link-logo {
    margin-top: 4px;
  }
  .hidden-xs {
    display: none !important;
  }
  .nav-home {
    height: auto;
  }
  #hoyo-about-title {
    text-align: center;
  }
  .about-content {
    padding: 40px 0px 40px 0px;
    text-align: left;
  }
  .testi-slot {
    padding: 0 0 20px 8px;
  }
}

/*For xs - md size*/
@media only screen and (max-width: 1279px) {

}

/*For xs - lg size*/
@media only screen and (max-width: 1919px) {

}

/*For xl size*/
@media only screen and (min-width: 1920px) {

}


/*For sm - xl size*/
@media only screen and (min-width: 600px) {
}
/*For md - xl size*/
@media only screen and (min-width:960px) {
  #dashboard-buttons-area button {
    /* color: #6b7c93; */
    color: rgb(107, 124, 147, 0.88);
    width: 153px;
    text-transform: none;
    padding-top: 17px;
    padding-bottom: 16px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(100, 100, 100, 0.5);
    font-weight: normal;
    background-color: white;
  }
  #dashboard-buttons-area button:hover{
    background-color: #e6e6e6;
  }

  #dashboard-buttons-area button {
    text-decoration: none;
    margin-bottom: 20px;
    text-decoration: none;
  }

  #dashboard-buttons-area button.active {
    color: white;
    background-color: #3d7dc8;
    text-decoration: none;
  }

}

/* lg & xl */
@media (min-width:1280px) {
  .container {
    width: 1140px !important;
  }

  #dashboard-buttons-area {
    margin-right: 60px;
  }
}

/* md only */
@media (min-width:960px) and (max-width: 1279px) {
  .container {
    width: 900px !important;
  }

  .db-box {
    padding: 14px 15px 16px;
  }
  #dashboard-buttons-area {
    margin-right: 50px;
  }
  .sub-navbar{
    width: 90%;
    margin: 0 auto;
  }
  .sub-navbar-change{
    width: 90%;
    margin: 0 auto;
  }
  .footer-content{
    width: 80%;
    text-align: justify;
  }

  .card {
    width: 100%;
  }

  #home-banner-container {
    padding-left: 0;
  }
  #navbar-menu-container {
    padding-left: 25px;
  }
  .testi-slot {
    padding: 10px;
  }
}

/* sm only */
@media (min-width:600px) and (max-width: 959px) {
  #dashboard-buttons-area button {
    /* color: #6b7c93; */
    color: rgb(107, 124, 147, 0.88);
    width: 153px;
    text-transform: none;
    padding-top: 17px;
    padding-bottom: 16px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(100, 100, 100, 0.5);
    font-weight: normal;
    background-color: white;
  }
  #dashboard-buttons-area button:hover{
    background-color: #e6e6e6;
  }

  #dashboard-buttons-area button {
    text-decoration: none;
    margin-bottom: 20px;
    text-decoration: none;
  }

  #dashboard-buttons-area button.active {
    color: white;
    background-color: #3d7dc8;
    text-decoration: none;
  }
  .container{
    width: 580px !important;
  }
  .center-sm {
    text-align: center;
  }
  #dashboard-buttons-area {
    margin: 0;
  }
  #dashboard-buttons-area button {
    width: 144px;
  }
  #dashboard-buttons-area a {
    text-decoration: none;
    margin-bottom: 5px;
  }
  #home-banner-container {
    padding-left: 0;
  }
}



/* xs only */
@media (max-width:599px) {
  .container {
    width: 92%;
  }
  #form-invest {
    text-align: left;
  }
  button.button-navbar{
    color: #1A100C;
    margin-left: 15px;
  }
  .sub-navbar {
    height: 80px;
  }
  .menu-other {
    text-align: left;
  }
  #home-banner-container {
    padding-left: 0;
    width: 81%;
    padding-top: 320px;
    text-align: center;
  }
  #home-banner-container button {
    width: 100%;
  }

  .testi-photo > div {
    width: 95%;
    height: 230px;
    margin: 0 auto;
  }
  .testi-content {
    padding: 10px;
  }
  .photo-story {
    width: 50%;
  }
  .mobile-grid-container {
    width: auto !important;
    margin: 0 !important;
  }
  .mobile-grid-container > div {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .photo-why {
    min-width: 140px;
  }
  .img-logo {
    margin-top: 25px;
  }
  #banner-title {
    font-size: 28px;
    text-align: left;
    line-height: inherit;
  }
  #banner-subtitle {
    display: none;
  }
  #bg-banner {
    background-image: url(/static/media/bg-banner-mobile.d403aec1.jpg);
    background-position: right top;
    background-size: cover;
    height: 680px;
  }
  #home-button-group {
    margin-top: 16px;
  }
  #menu-list-grow-user {
    width: 175px;
    background-color: #F5F4E9;
    border-radius: 10px;
  }
  .slick-slide .home-card {
    width: 290px;
  }
  #button-wa {
    right: 20px;
  }
  #bt-shopee {
    margin-right: 0;
  }
  #bt-toped {
    margin-top: 15px;
    margin-left: 0;
  }
  #container-testi {
    margin-top: 95px;
  }
}

/* special case */
@media (min-width:342px) and (max-width: 409px) {

}

@media (max-width:320px){

}



